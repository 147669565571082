import React from 'react';
import "./textarea.scss";

const TextArea = ({ value, onChange, ...props }) => {
    return ( 
        <textarea 
            value={value}
            onChange={onChange}
            className="text-area input"
            {...props}
        />
     );
}
 
export default TextArea;