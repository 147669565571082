/* eslint-disable import/no-anonymous-default-export */

export const types = {
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    GET_CATEGORIES: 'GET_CATEGORIES'
}

export default (state, action) => {
    switch(action.type){
        case types.CREATE_CATEGORY: 
            return{
                ...state,
                categories: [action.payload, ...state.categories]
            }
        case types.UPDATE_CATEGORY:
            return{
                ...state,
                categories: state.categories.map(cat => cat._id === action.payload._id ? action.payload : cat)
            }
        case types.DELETE_CATEGORY:
            return{
                ...state,
                categories: state.categories.filter(cat => cat._id !== action.payload)
            }
        case types.GET_CATEGORIES:
            return{
                ...state,
                categories: action.payload
            }
        default:
            return state;
    }
}