import FilterableItemsTableLayout from "components/layout/FilterableItemsTableLayout";
import Layout from "components/layout/Layout";
import PageActionHeader from "components/page-action-header/PageActionHeader";
import categoryContext from "context/category/categoryContext";
import useFilterItems from "hooks/useFilterItems";
import React, { useContext, useState, useEffect } from "react";
import CategoryForm from "./CategoryForm";
import CategoryItem from "./CategoryItem";
import SortCategories from "./SortCategories";

const CategoriesPage = () => {
  const [showModal, setShowModal] = useState(false);
  const { categories, createCategory } = useContext(categoryContext);
  const [categoriesList, setCategoriesList] = useState([...categories]);
  const filterFields = ["name"];

  const [filteredItems, searchText, setSearchText] = useFilterItems({
    items: categories,
    filterFields,
  });

  return (
    <Layout title={"Categorías"}>
      <PageActionHeader
        showModal={showModal}
        setShowModal={setShowModal}
        titleForm={"Nueva categoría"}
        titleButton={"Nueva categoría"}
      >
        <CategoryForm
          submitCategory={createCategory}
          handleClose={() => setShowModal(false)}
        />
      </PageActionHeader>

      <FilterableItemsTableLayout
        searchText={searchText}
        setSearchText={setSearchText}
        filteredLength={filteredItems.length}
      >
        <SortCategories categories={categories} />
        {filteredItems.map((category, i) => (
          <CategoryItem category={category} key={`cat${i}`} />
        ))}
      </FilterableItemsTableLayout>
    </Layout>
  );
};

export default CategoriesPage;
