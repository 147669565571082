import { useEffect, useReducer, useState } from "react";
import axiosClient from "../../config/axiosClient";
import tokenHeader from "../../config/tokenHeader";
import authContext from "./authContext";
import authReducer from "./authReducer";

const AuthState = (props) => {

    const initialState = {
        isAuth: false,
        user: null,
        loginError: null,
    }
    const [checkLoading, setCheckLoading] = useState(true);
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(async () => {
       await checkAuthState();
       setCheckLoading(false);
    }, [])

    const checkAuthState = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: token
            })
        }
        if (token) {
            await tokenHeader();
            try {
                const res = await axiosClient.get('/auth/check');
                // dispatch({
                //     type: 'LOGIN',
                //     payload: res.data.user
                // });
            } catch (error) {
                if (error.response.status === 401) {
                    dispatch({
                        type: 'LOGOUT_SUCCESS',
                    });
                }
            }
        }
    }

    const login = async ({email, password}) => {

        try {
            const res = await axiosClient.post('/login', {email, password});
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: res.data.token
            })
        } catch (error) {
            dispatch({
                type: 'LOGIN_ERROR',
                payload: "Credenciales incorrectas"
            })
        }
    }

    const logout = async () => {
        try {
            dispatch({
                type: 'LOGOUT_SUCCESS'
            })
        } catch (error) {
        
        }
    }


    return (  
        <authContext.Provider value={{
            isAuth: state.isAuth,
            user: state.user,
            login,
            logout,
            loginError: state.loginError,
            checkLoading
        }}
        >
            {props.children}
        </authContext.Provider>
    );
}
 
export default AuthState;