import { useState } from "react";

const useFilterItems = ({items, filterFields}) => {
   
    const [searchText, setSearchText] = useState("");

    const searchInItemsByAllFields = () => {
        const normalizedSearchText = (text) =>
          text
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        return items.filter((item) => {
          return filterFields.some((field) => {
            if (typeof field === "string") {
              return normalizedSearchText(item[field])
                .toLowerCase()
                .includes(normalizedSearchText(searchText).toLowerCase());
            } else {
              return normalizedSearchText(item[field.field][field.subField])
                .toLowerCase()
                .includes(normalizedSearchText(searchText).toLowerCase());
            }
          });
        });
      };

    const filteredItems = searchText !== "" ? searchInItemsByAllFields() : items;
    return [filteredItems, searchText, setSearchText]
}
 
export default useFilterItems;