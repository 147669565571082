import { useContext, useState } from "react";
import categoryContext from "context/category/categoryContext";
import FormLayout from "components/form/FormLayout";
import FormInput from "components/input/FormInput";
import LoadingTwo from "components/loading/LoadingTwo";
import { localContext } from "context/local/localState";

const ProductForm = ({ product, submitProduct, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: product ? product.name : "",
    category: product
      ? { value: product.category._id, label: product.category.name }
      : {},
    price: product ? product.price : "",
    description: product ? product.description : "",
    local: product?.local || [],
  });
  const { categories } = useContext(categoryContext);
  const optionsCategory = categories.map((category) => ({
    value: category._id,
    label: category.name,
  }));
  const { locals } = useContext(localContext);

  const handleSubmit = async () => {
    const newProduct = {
      ...values,
      category: values.category.value,
    };
    if (product) newProduct._id = product._id;
    setLoading(true);
    await submitProduct(newProduct);
    setLoading(false);
    clearForm();
    handleClose && handleClose();
  };

  const clearForm = () => {
    setValues({
      name: "",
      category: {},
      price: 0,
      description: "",
    });
  };
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

 
  const localOptions = locals.map((local) => ({
    value: local._id,
    label: local.name,
  }));


  const handleChangeLocal = (locals) => {
    setValues({
      ...values,
      local: locals
    })
  }

  return (
    <>
      {loading && <LoadingTwo />}
      <FormLayout onSubmit={handleSubmit} buttonText="Guardar">
        <FormInput
          name="name"
          value={values.name}
          onChange={handleChange}
          label="Nombre del producto"
          errorMessage="El nombre del producto debe tener mínimo 3 caracteres"
          pattern={`.{3,}`}
          placeholder="Nombre del producto"
          required
        />
        <FormInput
          type="select-search"
          name="category"
          value={values.category}
          onChange={handleChange}
          options={optionsCategory}
          label="Categoria"
          errorMessage="Selecciona una categoria"
          required
        />
        <FormInput
          type="number"
          name="price"
          value={values.price}
          onChange={handleChange}
          label="Precio"
          min={0}
          step={0.01}
          placeholder="0.00"
          required
        />
        <FormInput
          type="textarea"
          name="description"
          value={values.description}
          onChange={handleChange}
          label="Descripción"
          placeholder="Descripción del producto"
        />
        <FormInput
          type="multiple-select"
          name="local"
          value={values.local}
          onChange={handleChangeLocal}
          options={localOptions}
          label="Local"
          errorMessage="Selecciona un local"
          required
        />
      </FormLayout>
    </>
  );
};

export default ProductForm;
