import Branding from "components/branding/Branding";
import Menu from "components/menu/Menu";
import React from "react";

const Layout = ({ children, title }) => {
  return (
    <div className="App">
      <header className="header small-column">
        <Menu title={title} />
        <Branding />
      </header>
      {children}
    </div>
  );
};

export default Layout;
