import React from "react";
import "././ButtonMenu.scss";

const ButtonMenu = ({ onClick, isOpen }) => {
  return (
    <div
      class={`menu btn4 ${isOpen ? "open" : ""}`}
      data-menu="4"
      onClick={onClick}
    >
      <div class="icon"></div>
    </div>
  );
};

export default ButtonMenu;
