import WarningIcon from 'assets/svg/WarningIcon';
import React, {useState} from 'react';
import "./confirm.scss"

const Confirm = ({msg, onClick, children}) => {

    const [openConfirm, setOpenConfirm] = useState(false);
    const onClose = () => {
       setOpenConfirm(!openConfirm);
    }
    const onConfirm = () => {
        onClick();
        setOpenConfirm(!openConfirm);
    }

    return (  
        <>
        <div onClick={() => setOpenConfirm(true)}>
            {children}
         </div>
            {openConfirm &&
            <>
            <div className="confirm-overlay" onClick={onClose} />
            <div className="confirm-container">
                <div className="confirm-content">
                    <WarningIcon className="confirm-warning"/>
                    <div className="confirm-msg">{msg || '¿Desea completar esta acción?'}</div>
                    <div className="confirm-btn-container">
                        <button className="confirm-btn" onClick={onConfirm}>Sí</button>
                        <button className="confirm-btn confirm-btn--bold" onClick={onClose}>Cancelar</button>
                    </div>
                </div>
            </div>
            </>
        }
       </>
    );
}
 
export default Confirm;
