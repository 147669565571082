import FilterableItemsTableLayout from "components/layout/FilterableItemsTableLayout";
import Layout from "components/layout/Layout";
import PageActionHeader from "components/page-action-header/PageActionHeader";
import useFilterItems from "hooks/useFilterItems";
import { useContext, useState } from "react";
import { localContext } from "context/local/localState";
import LocalForm from "./LocalForm";
import LocalItem from "./LocalItem";

const LocalPage = () => {
  const [showModal, setShowModal] = useState(false);
  const { locals, createLocal } = useContext(localContext);
  const filterFields = ["name"];
  const [filteredItems, searchText, setSearchText] = useFilterItems({
    items: locals,
    filterFields,
  });

  return (
    <Layout title="Locales">
      <PageActionHeader
        showModal={showModal}
        setShowModal={setShowModal}
        titleForm={"Nuevo ítem"}
      >
        <LocalForm
          submitLocal={createLocal}
          handleClose={() => setShowModal(false)}
        />
      </PageActionHeader>
      <FilterableItemsTableLayout
        searchText={searchText}
        setSearchText={setSearchText}
        filteredLength={filteredItems.length}
      >
        {filteredItems.map((local) => (
          <LocalItem key={local.id} local={local} />
        ))}
      </FilterableItemsTableLayout>
    </Layout>
  );
};

export default LocalPage;
