import Button from "components/button/Button";
import Confirm from "components/confirm/Confirm";
import ModalForm from "components/modal/ModalForm";
import ProductForm from "pages/ProductPage/product-form/ProductForm";
import productContext from "context/product/productContext";
import { useContext, useState } from "react";
import "./productActions.scss";
import Icon from "components/icon/Icon";

const ProductActions = ({ product }) => {
  const soldOutText = product.soldOut ? "disponible" : "agotado";
  const [showModal, setShowModal] = useState(false);
  const { deleteProduct, updateProduct } = useContext(productContext);

  const handleSoldOut = () => {
    updateProduct({ _id: product._id, soldOut: !product.soldOut });
  };

  return (
    <div className="product-actions">
      <Button
        onClick={handleSoldOut}
        primary={product.soldOut}
        secondary={!product.soldOut}
        small={true}
      >
        Marcar como {soldOutText}
      </Button>
      <Confirm onClick={() => deleteProduct(product._id)}>
        <Icon icon="trash" isBtn />
      </Confirm>
      <Icon onClick={() => setShowModal(true)} icon="edit" isBtn />
      <ModalForm
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        title="Editar producto"
      >
        {showModal && (
          <ProductForm
            submitProduct={updateProduct}
            handleClose={() => setShowModal(false)}
            product={product}
          />
        )}
      </ModalForm>
    </div>
  );
};

export default ProductActions;
