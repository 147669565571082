import React from "react";
import "pages/ProductPage/product-item/product.scss";
import Confirm from "components/confirm/Confirm";
import ModalForm from "components/modal/ModalForm";
import { useContext, useState } from "react";
import Icon from "components/icon/Icon";
import LocalForm from "./LocalForm";
import { localContext } from "context/local/localState";
import "pages/ProductPage/product-actions/productActions.scss"

const LocalActions = ({ local }) => {
  const [showModal, setShowModal] = useState(false);
  const { deleteLocal, updateLocal } = useContext(localContext);
  return (
    <div className="product-actions">
      <Confirm onClick={() => deleteLocal(local._id)}>
        <Icon icon="trash" isBtn />
      </Confirm>
      <Icon onClick={() => setShowModal(true)} icon="edit" isBtn />
      <ModalForm
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        title="Editar local"
      >
        {showModal && (
          <LocalForm
            submitLocal={updateLocal}
            handleClose={() => setShowModal(false)}
            local={local}
          />
        )}
      </ModalForm>
    </div>
  );
};

const LocalItem = ({ local }) => {
  return (
    <div className="product-item" key={local.id}>
      {local.image && local.image.trim() !== "" && (
        <div className="product-item__image">
          <img src={local.image} alt={local.name} />
        </div>
      )}
      <div className="product-item__text">
        <h1 className="product-item__name">{local.name}</h1>
        <p className="product-item__description">{local.description}</p>
      </div>
      <LocalActions local={local} />
    </div>
  );
};

export default LocalItem;
