const WarningIcon = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.533 17.9991H0.999975C0.642714 17.9991 0.312595 17.8085 0.133968 17.4991C-0.0446583 17.1897 -0.0446559 16.8085 0.133975 16.4991L9.39997 0.499091C9.57874 0.190202 9.90858 0 10.2655 0C10.6224 0 10.9522 0.190202 11.131 0.499091L20.397 16.4991C20.5755 16.8083 20.5756 17.1893 20.3972 17.4986C20.2188 17.808 19.8891 17.9987 19.532 17.9991H19.533ZM10.267 2.99909L2.73597 15.9991H17.8L10.267 2.99909ZM11.262 12.0001H9.26197V6.99909H11.262V12.0001Z"
        fill="currentColor"
      />
      <path
        d="M9.26697 13.0011H11.267V15.0011H9.26697V13.0011Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WarningIcon;
