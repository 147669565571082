import ItemTitle from 'components/item-title/ItemTitle';
import React from 'react';
import CategoryActions from './CategoryActions';
import './categoryItem.scss'

const CategoryItem = ({ category }) => {
    return ( 
        <li className="category-item fx">
            <div className="category-item__image">
                <img src={category.image || 'https://via.placeholder.com/150'} alt=""/>
            </div>
            <div className='category-item__body'>
                <div className="category-item__text">
                  <ItemTitle>{category.name}</ItemTitle>
                </div>
                <CategoryActions category={category}/>
            </div>
        </li>
     );
}
 
export default CategoryItem;