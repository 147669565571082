import routesApp from 'routing/routes';
import LoginPage from 'pages/LoginPage';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import RequireLogin from 'routing/RequireLogin';
 
const RouterApp = () => {
    return (  
        <div className="App">
        <Router>
          <Routes>
              <Route path='/login' exact={true}  element={<LoginPage />} />
              {routesApp.map((route, index) => (
                <Route 
                key={index} 
                path={route.path} 
                exact={route.exact} 
                element={
                  <RequireLogin>
                    {route.element}
                  </RequireLogin>
                } 
                />
              ))}
          </Routes>
        </Router>
        </div>
    );
}
 
export default RouterApp;