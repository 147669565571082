import React from 'react';
import "./branding.scss"
import logo from 'assets/images/logo.png';

const Branding = () => {
    return ( 
        <div className="branding">
            <div className="branding__logo">
                <img src={logo} alt="Keep logo" />
            </div>
            <div className="branding__text">
                <p>Panel de gestión</p>
            </div>
        </div>
     );
}
 
export default Branding;