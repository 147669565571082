import AuthState from "context/auth/authState";
import CategoryState from "context/category/categoryState";
import LocalState from "context/local/localState";
import ProductState from "context/product/productState";
import RouterApp from "routing/RouterApp";
import "./assets/css/App.scss";

function App() {
  return (
    <div className="App">
      <AuthState>
        <LocalState>
          <CategoryState>
            <ProductState>
              <RouterApp />
            </ProductState>
          </CategoryState>
        </LocalState>
      </AuthState>
    </div>
  );
}

export default App;
