import ModalForm from "components/modal/ModalForm";
import React, { useState, useEffect, useContext } from "react";
import "./sortCategories.scss";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import tokenHeader from "config/tokenHeader";
import axiosClient from "config/axiosClient";
import LoadingTwo from "components/loading/LoadingTwo";
import categoryContext from "context/category/categoryContext";

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="sort-category__list">
      {items.map((item, index) => (
        <SortableItem key={item._id} item={item} index={index} />
      ))}
    </ul>
  );
});

const SortableItem = SortableElement(({ item }, index) => {
  return (
    <li key={item._id} index={index} className="sort-category__item">
      <span>{item.name}</span>
    </li>
  );
});

const SortCategories = ({ categories: defaultCategories }) => {
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setStateCategories } = useContext(categoryContext);

  useEffect(() => {
    setCategories(defaultCategories);
  }, [defaultCategories]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const categoriesOrder = arrayMoveImmutable(categories, oldIndex, newIndex);
    setCategories(categoriesOrder);
  };

  const handleOrderSubmit = async () => {
    const categoriesIds = categories.map((category) => category._id);
    try {
      setLoading(true);
      await tokenHeader();
      await axiosClient.put("/item-order", {
        name: "categories",
        orderedIds: categoriesIds,
      });
      setStateCategories(categories);
      setLoading(false);
      handleCloseModal();
    } catch (error) {
    }
  };

  const handleCloseModal = () => setShowModal(false);
  return (
    <>
    {loading && <LoadingTwo />}
      <ModalForm
        showModal={showModal}
        handleClose={handleCloseModal}
        title="Ordenar categorías"
      >
        <div className="p03">
          <SortableList items={categories} onSortEnd={onSortEnd} />
          <button className="button--form" onClick={handleOrderSubmit}>
            Guardar
          </button>
        </div>
      </ModalForm>
      <div className="sort-action-container">
        <button onClick={() => setShowModal(true)}>Ordenar</button>
      </div>
    </>
  );
};

export default SortCategories;
