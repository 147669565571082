import { useState } from "react";
import FormLayout from "components/form/FormLayout";
import FormInput from "components/input/FormInput";
import LoadingTwo from "components/loading/LoadingTwo";

const LocalForm = ({ local, submitLocal, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: local?.name || "",
  });

  const handleSubmit = async () => {
    const newLocal = {
      ...values,
    };
    if (local) newLocal._id = local._id;
    setLoading(true);
    await submitLocal(newLocal);
    setLoading(false);
    clearForm();
    handleClose && handleClose();
  };

  const clearForm = () => {
    setValues({
      name: "",
      category: {},
      price: 0,
      description: "",
    });
  };
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {loading && <LoadingTwo />}
      <FormLayout onSubmit={handleSubmit} buttonText="Guardar">
        <FormInput
          name="name"
          value={values.name}
          onChange={handleChange}
          label="Nombre del local"
          errorMessage="El nombre del local debe tener mínimo 3 caracteres"
          pattern={`.{3,}`}
          placeholder="Nombre del local"
          required
        />
      </FormLayout>
    </>
  );
};

export default LocalForm;
