import React, { useState, useEffect, useContext } from "react";
import "./menu.scss";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import routesApp from "routing/routes";
import ButtonMenu from "components/btn-menu/ButtonMenu";
import authContext from "context/auth/authContext";

const Menu = ({ title = 'Menú' }) => {
  const [collapse, setCollapse] = useState(false);
  const navbarCollapse = React.useRef();
  const { logout } = useContext(authContext);

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      navbarCollapse.current &&
      !navbarCollapse.current.contains(event.target)
    ) {
      setCollapse(false);
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light"
      ref={navbarCollapse}
    >
      <Link className="navbar-brand" to="#">
        {title}
      </Link>
      <ButtonMenu onClick={() => setCollapse(!collapse)} isOpen={collapse} />
      <div
        className={`${collapse && "collapse"} navbar-collapse`}
        id="navbarNav"
      >
        <ul className="navbar-nav">
          {routesApp.map(
            (route, index) =>
              route.showInMenu && (
                <li className="nav-item active" key={index}>
                  <CustomLink
                    className="nav-link"
                    activeClassName={"active"}
                    to={route.path}
                  >
                    {route.label}
                  </CustomLink>
                </li>
              )
          )}
         <li className="nav-item" onClick={logout} style={{ marginTop: "10px" }}>
             <span className="nav-link">Cerrar sesión</span>
         </li>
        </ul>
      </div>
    </nav>
  );
};

export default Menu;

const CustomLink = ({ children, className, activeClassName, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      className={`${className} ${match ? activeClassName : ""}`}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
};
