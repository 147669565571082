import axiosClient from "config/axiosClient";
import tokenHeader from "config/tokenHeader";
import { useEffect, useReducer } from "react";
import categoryContext from "./categoryContext";
import categoryReducer from "./categoryReducer";


const CategoryState = (props) => {

    const initialState = {
        categories: []
    }

    const [state, dispatch] = useReducer(categoryReducer, initialState)
    const getCategories = async () => {
        try {
            const res = await axiosClient.get('/category')

            dispatch({
                type: 'GET_CATEGORIES',
                payload: res.data.docs
            })
        } catch (error) {
         
        }
    }
    useEffect(() => {
        getCategories()
    }, [])

    const createCategory = async (category) => {
        try {
            await tokenHeader();
            const res = await axiosClient.post('/category', category)

            dispatch({
                type: 'CREATE_CATEGORY',
                payload: res.data.category
            })
    
            
        } catch (error) {
        }
    }

    const updateCategory = async (category, id) => {
        try {
            await tokenHeader();
            const res = await axiosClient.put(`/category/${id}`, category)

            dispatch({
                type: 'UPDATE_CATEGORY',
                payload: res.data.category
            })
    
        } catch (error) {

        }
    }

    const deleteCategory = async (id) => {
        try {
            await tokenHeader();
            await axiosClient.delete(`/category/${id}`)

            dispatch({
                type: 'DELETE_CATEGORY',
                payload: id
            })

        } catch (error) {

        }
    }

    const setStateCategories = (categories) => {
        dispatch({
            type: 'GET_CATEGORIES',
            payload: categories
        })
    }

    return (  
        <categoryContext.Provider value={{
            categories: state.categories,
            getCategories,
            createCategory,
            updateCategory,
            deleteCategory,
            setStateCategories
        }}>
            {props.children}
        </categoryContext.Provider>
    );
}
 
export default CategoryState;