import Branding from "components/branding/Branding";
import Button from "components/button/Button";
import FormInput from "components/input/FormInput";
import authContext from "context/auth/authContext";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.scss";

const LoginPage = () => {
  const { isAuth, login, loginError } = useContext(authContext);
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    if (isAuth) {
      navigate("/office");
    }
  }, [isAuth, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email, password });
  };

  return (
    <div className="login-page small-column">
      <Branding />
      <form className="login-form" onSubmit={handleSubmit}>
        <FormInput
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo electrónico"
          label="Correo electrónico"
          required
        />
        <FormInput
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          label="Contraseña"
          required
        />
        <div className="login-form__button">
          <Button type="submit" primary>
            Ingresar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
