import ProductActions from "pages/ProductPage/product-actions/ProductActions";
import React from "react";
import "./product.scss";

const ProductItem = ({ product }) => {
  return (
    <div className="product-item" key={product.id}>
      {product.image && product.image.trim() !== "" && (
        <div className="product-item__image">
          <img src={product.image} alt={product.name} />
        </div>
      )}
      <div className="product-item__text">
        <h1 className="product-item__name">{product.name}</h1>
        <p className="product-item__description">{product.description}</p>
        <p className="product-item__price">${product.price}</p>
      </div>
      <div className="product-item__category-container">
        <p className="product-item__category">{product.category?.name}</p>
      </div>
      <ProductActions product={product} />
    </div>
  );
};

export default ProductItem;
