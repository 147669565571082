import icons from 'assets/icons/icons';
import React from 'react';
import "./icon.scss"

const Icon = ({src, alt, icon, onClick, isBtn}) => {
    return (  
      <div onClick={onClick} className={isBtn && 'btn-icon'}>
        <div className="icon">
             <img src={icon ? icons[icon] : src} alt={alt || 'icon'} />
        </div>
      </div>
    );
}
 
export default Icon;