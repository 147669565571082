import { useState } from "react";
import "./input.scss";

const Input = (props) => {
  const [focused, setFocused] = useState(false);
  const {
    label,
    errorMessage,
    onChange,
    id,
    required = false,
    type = "text",
    value,
    name,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <section className="input-container">
      <input
        {...inputProps}
        name={name}
        type={type}
        required={required}
        onChange={onChange}
        value={value}
        onBlur={handleFocus}
        onFocus={() =>
          name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
        className="input-primary input"
      />
    </section>
  );
};

export default Input;
