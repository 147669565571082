import axiosClient from './axiosClient'

const tokenHeader = async () => {

    const token = localStorage.getItem('token');
    
    if (token) {
        axiosClient.defaults.headers.common['x-app-token'] = token;
    } else{
        delete axiosClient.defaults.headers.common['x-app-token'];
    }
}

export default tokenHeader;