import Layout from "components/layout/Layout";
import axiosClient from "config/axiosClient";
import React, { useState, useEffect } from "react";
import MetadataItem from "./MetadataItem";
import "./metadata.scss";
import LoadingTwo from "components/loading/LoadingTwo";

const MetadataPage = () => {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const handleGetTasaBcv = async () => {
    try {
      setLoading(true);
      const res = await axiosClient.get("/metadata/tasa-bcv");
      setItem(res.data);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    handleGetTasaBcv();
  }, []);
  return (
    <Layout title={"Información adicional"}>
      {loading && <LoadingTwo />}
      <section className="metadata-page container small-column p03">
        <MetadataItem item={item} />
      </section>
    </Layout>
  );
};

export default MetadataPage;
