import React from 'react';

const SearchBar = ({searchText, setSearchText}) => {
    return (  
        <div className="search-bar mb2">
            <input 
                type="text" 
                placeholder="Buscar..." 
                className='input' 
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
        </div>
    );
}
 
export default SearchBar;