import { useState } from "react";
import FormLayout from "components/form/FormLayout";
import FormInput from "components/input/FormInput";
import LoadingTwo from "components/loading/LoadingTwo";
import "./categoryForm.scss";
import Icon from "components/icon/Icon";

const CategoryForm = ({ category, submitCategory, handleClose }) => {
  const [values, setValues] = useState({
    name: category?.name || "",
    imageUrl: category?.image || "",
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    const newCategory = { ...values };
    const categoryId = category ? category._id : null;
    // create formdata by object values
    const formData = new FormData();
    Object.keys(newCategory).forEach((key) => {
      formData.append(key, newCategory[key]);
    });
    setLoading(true);
    await submitCategory(formData, categoryId);
    clearForm();
    setLoading(false);
    handleClose && handleClose();
  };

  const clearForm = () => {
    setValues({
      name: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onChangeFile = (file) => {
    const url = URL.createObjectURL(file);
    setValues({
      ...values,
      image: file,
      imageUrl: url,
    });
  };

  const handleDeleteImage = () => {
    setValues({
      ...values,
      image: "",
      imageUrl: "",
      deleteImage: true
   });
  }

  return (
    <>
      {loading && <LoadingTwo />}
      <FormLayout onSubmit={handleSubmit} buttonText={"Guardar"}>
        <FormInput
          name="name"
          value={values.name}
          onChange={handleChange}
          label="Nombre"
          placeholder="Nombre de la categoría"
          errorMessage="El nombre de la categoría debe tener mínimo 3 caracteres"
          pattern=".{3,}"
          required
        />
        <FormInput
          name="image"
          type="file"
          label="Imagen"
          placeholder="Cargar imagen"
          accept=".png, .jpg, .jpeg"
          onChange={onChangeFile}
          fileName={values?.image?.name}
        />
              {values?.imageUrl && (
                <section className="category-form__image">
                  <section className="category-form__image-wrapper">
                    <img src={values.imageUrl} alt="category" />
                    <section className="category-form__trash-image">
                      <Icon icon={'trash'} isBtn onClick={handleDeleteImage} />
                    </section>
                  </section>
                </section>
              )}
      </FormLayout>
    </>
  );
};

export default CategoryForm;
