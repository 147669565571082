import CloseIcon from "assets/svg/CloseIcon";
import React, { useEffect, useState } from "react";
import "./selectsearch.scss";

const SelectSearch = ({
  options,
  onChange,
  value,
  name,
  errorMessage = "Selecciona una categoría",
}) => {
  const [focus, setFocus] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const containerRef = React.useRef(null);
  const inputRef = React.useRef();
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setFocus(false);
    }
  };
  const onSearch = (value) => {
    setSearchText(value);
    let results = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(results);
  };
  const onSelect = (option) => {
    onChange({ target: { name, value: option } });
    setFocus(false);
  };
  const onClear = () => {
    onChange({ target: { name, value: {} } });
    setFocus(true);
    setSearchText("");
  };
  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);
  const showResults = searchText ? searchResults : options;
  const selected = Object.keys(value).length > 0;
  return (
    <div className="select-search input" ref={containerRef}>
      <div
        className={`formInput__input select-false-input ${
          !selected ? "select-none" : ""
        }`}
      >
        <p>{selected && value.label}</p>
        <button
          className="select-search__clear"
          onClick={onClear}
          type="button"
        >
          <CloseIcon />
        </button>
      </div>
      <input
        type={"text"}
        className={`formInput__input ${selected ? "select-none" : ""}`}
        placeholder={"Buscar"}
        onFocus={() => setFocus(true)}
        onChange={(e) => onSearch(e.target.value)}
        value={searchText}
        ref={inputRef}
      />
      {!selected && <span className="error">{errorMessage}</span>}
      <ul className={`select-options ${!focus ? "select-none" : ""}`}>
        {showResults.map((option) => (
          <li key={option.value} onClick={() => onSelect(option)}>
            <a href={"#"}>{option.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectSearch;
