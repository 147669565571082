import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import authContext from "../context/auth/authContext";

const RequireLogin = ({ children }) => {
  const { isAuth, checkLoading } = useContext(authContext);

  if (!checkLoading && !isAuth) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequireLogin;
