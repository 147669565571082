import Confirm from "components/confirm/Confirm";
import categoryContext from "context/category/categoryContext";
import { useContext } from "react";
import useModal from "hooks/useModal/useModal";
import CategoryForm from "./CategoryForm";
import Icon from "components/icon/Icon";

const CategoryActions = ({ category }) => {
  const [isOpenEdit, openEdit, closeEdit, ModalEdit] = useModal();
  const { deleteCategory, updateCategory } = useContext(categoryContext);

  return (
    <div className="category-actions fx">
      <Confirm onClick={() => deleteCategory(category._id)}>
        <Icon icon={'trash'} isBtn />
      </Confirm>
      <Icon onClick={openEdit} icon={'edit'} isBtn/>
      <ModalEdit isForm={true} title="Editar categoría">
        {isOpenEdit && (
          <CategoryForm
            submitCategory={updateCategory}
            handleClose={closeEdit}
            category={category}
          />
        )}
      </ModalEdit>
    </div>
  );
};

export default CategoryActions;
