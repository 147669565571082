import FilterableItemsTableLayout from "components/layout/FilterableItemsTableLayout";
import Layout from "components/layout/Layout";
import PageActionHeader from "components/page-action-header/PageActionHeader";
import ProductForm from "pages/ProductPage/product-form/ProductForm";
import ProductItem from "pages/ProductPage/product-item/ProductItem";
import productContext from "context/product/productContext";
import useFilterItems from "hooks/useFilterItems";
import { useContext, useState } from "react";

const ProductPage = () => {
  const [showModal, setShowModal] = useState(false);
  const { products, createProduct } = useContext(productContext);
  const filterFields = [
    "name",
    "description",
    "price",
    { field: "category", subField: "name" },
  ];

  const [filteredItems, searchText, setSearchText] = useFilterItems({
    items: products,
    filterFields,
  });

  return (
    <Layout title='Productos'>
      <PageActionHeader
        showModal={showModal}
        setShowModal={setShowModal}
        titleForm={"Nuevo ítem"}
      >
        <ProductForm
          submitProduct={createProduct}
          handleClose={() => setShowModal(false)}
        />
      </PageActionHeader>
      <FilterableItemsTableLayout
        searchText={searchText}
        setSearchText={setSearchText}
        filteredLength={filteredItems.length}
      >
        {filteredItems.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </FilterableItemsTableLayout>
    </Layout>
  );
};

export default ProductPage;
