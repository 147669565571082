import Button from "components/button/Button";
import ModalForm from "components/modal/ModalForm";
import React from "react";

const PageActionHeader = ({ showModal, setShowModal, children, titleForm, titleButton }) => {
  return (
      <div className="container small-column">
        <Button secondary onClick={() => setShowModal(true)}>
          {titleButton || "Nuevo ítem"}
        </Button>
        <ModalForm
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            title={titleForm}
        >
            {children}
        </ModalForm>
    </div>
  );
};

export default PageActionHeader;
