import Button from "components/button/Button";
import LoadingTwo from "components/loading/LoadingTwo";
import axiosClient from "config/axiosClient";
import tokenHeader from "config/tokenHeader";
import React, { useEffect, useState } from "react";

const MetadataItem = ({ item }) => {
  const { title, value: defaultValue, _id } = item;
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const formRef = React.createRef();
  const handleFocusAndUnfocus = (e) => {
    const isFocus = e.type === "focus";
    setFocus(isFocus);
  };
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await tokenHeader();
      await axiosClient.put(`/metadata/${_id}`, { value });
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      {loading && <LoadingTwo />}
      <section clasName="metadata-item">
        <span className="metadata__title">{title}</span>
        <form onSubmit={handleSubmit} ref={formRef}>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={handleFocusAndUnfocus}
          />
          <Button primary type={"submit"}>
            Guardar
          </Button>
        </form>
      </section>
    </>
  );
};

export default MetadataItem;
