import Icon from "components/icon/Icon";
import React from "react";
import "./button.scss";

const Button = (props) => {
  const {
    onClick,
    children,
    primary,
    secondary,
    small,
    leftIcon,
    rightIcon,
    onClickIcon,
    type = 'button',
    wfull,
    ...otherProps
  } = props;
  const className = `
            button
            ${primary ? "button--primary" : ""}
            ${secondary ? "button--secondary" : ""}
            ${small ? "button--small" : ""}
            ${wfull ? "button--wfull" : ""}`;

  return (
    <div className={className}>
      <div className="fx2">
        {leftIcon && <Icon icon={leftIcon} onClick={onClickIcon} />}
        <button onClick={onClick} type={type} {...otherProps}>
          {children}
        </button>
        {rightIcon && (
          <span className="button-right-icon">
            <Icon icon={rightIcon} onClick={onClickIcon} />
          </span>
        )}
      </div>
    </div>
  );
};

export default Button;
