import { useState } from 'react';
import './modal.scss'

const useModal = (initialValue = false) => {
    const [isOpenModal, setIsOpenModal] = useState(initialValue);

    const closeModal = () => {
        setIsOpenModal(false);
    };
    const openModal = () => {
        setIsOpenModal(true);
    };

    const Modal = ({ children, isForm, title }) => {
        const openClassName = isOpenModal ? "modal display-block" : "modal display-none";
      
        return (
          <div className={openClassName}>
            <section className="modal-main">
              {isForm ? (
                         <div className="small-column">
                         <div className="form-header fx24 p23">
                             <h1>{title}</h1>
                             <button type="button" onClick={closeModal}>X</button>
                         </div>
                             {children}
                         </div>  
              ): (
                {children}
              )}
            </section>
          </div>
        );
      };

    return [isOpenModal, openModal, closeModal, Modal];
};

export default useModal;