const CloseIcon = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99713 1.98016C9.40114 1.60361 9.42341 0.970836 9.04686 0.566822C8.6703 0.162808 8.03753 0.140545 7.63352 0.517097L4.70739 3.24432L1.98016 0.318197C1.60361 -0.0858172 0.970836 -0.10808 0.566822 0.268472C0.162808 0.645023 0.140545 1.2778 0.517097 1.68181L3.24433 4.60794L0.318197 7.33517C-0.0858173 7.71172 -0.10808 8.34449 0.268472 8.74851C0.645024 9.15252 1.2778 9.17478 1.68181 8.79823L4.60794 6.071L7.33517 8.99713C7.71172 9.40114 8.34449 9.42341 8.74851 9.04686C9.15252 8.6703 9.17478 8.03753 8.79823 7.63352L6.071 4.70739L8.99713 1.98016Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
