import Button from "components/button/Button";
import Icon from "components/icon/Icon";
import React from "react";

const InputFile = ({ onChange, placeholder, fileName = "", ...others }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onChange(fileUploaded);
  };

  const showTextByLength = (text) => {
    if (text.length > 20) {
      return text.substring(0, 20) + "...";
    } else {
      return text;
    }
  };

  const clearFile = () => {
    onChange(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        secondary
        rightIcon={fileName ? "close" : null}
        onClickIcon={clearFile}
        wfull
      >
        <div className="fx2">
          <Icon icon={"upload"} />
          <span style={{ marginLeft: ".5rem" }}>
            {showTextByLength(fileName) || placeholder || "Subir imagen"}
          </span>
        </div>
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        {...others}
      />
    </>
  );
};

export default InputFile;
