import SearchBar from 'components/search-bar/SearchBar';
import React from 'react';

const FilterableItemsTableLayout = ({searchText, setSearchText, filteredLength, children}) => {
    const isSearchTextEmpty = searchText === "";
    return (  
        <div className="filterable-item-table container">
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
        {!isSearchTextEmpty && (
          <p>
            Encontrado: {filteredLength} resultado
            {(!filteredLength === 0 || filteredLength > 1) && "s"}
          </p>
        )}
       <div className="item-table small-column">
              <div className="items-row">
                  {children}
              </div>
          </div>
      </div>
    );
}
 
export default FilterableItemsTableLayout;