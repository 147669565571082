import axiosClient from "config/axiosClient";
import tokenHeader from "config/tokenHeader";

/* eslint-disable default-case */
const { useReducer, createContext, useEffect } = require("react");
export const localContext = createContext();

const localTypes = {
  GET_LOCALS: "GET_LOCALS",
  CREATE_LOCAL: "CREATE_LOCAL",
  UPDATE_LOCAL: "UPDATE_LOCAL",
  DELETE_LOCAL: "DELETE_LOCAL",
};

const localReducer = (state, action) => {
  switch (action.type) {
    case localTypes.GET_LOCALS:
      return {
        ...state,
        locals: action.payload,
      };
    case localTypes.CREATE_LOCAL:
      return {
        ...state,
        locals: [action.payload, ...state.locals],
      };
    case localTypes.UPDATE_LOCAL:
      return {
        ...state,
        locals: state.locals.map((local) =>
          local._id === action.payload._id ? action.payload : local
        ),
      };
    case localTypes.DELETE_LOCAL:
      return {
        ...state,
        locals: state.locals.filter((local) => local._id !== action.payload),
      };
  }
};

const LocalState = ({ children }) => {
  const initialState = {
    locals: [],
  };

  const [state, dispatch] = useReducer(localReducer, initialState);

  const getLocals = async () => {
    try {
      const { data: locals } = await axiosClient.get("/local");
      dispatch({
        type: localTypes.GET_LOCALS,
        payload: locals,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getLocals();
  }, []);

  const createLocal = async (local) => {
    try {
      await tokenHeader();
      const res = await axiosClient.post("/local", local);

      dispatch({
        type: localTypes.CREATE_LOCAL,
        payload: res.data.local,
      });
    } catch (error) {}
  };
  const updateLocal = async (local) => {
    try {
      await tokenHeader();
      const res = await axiosClient.put(`/local/${local._id}`, local);

      dispatch({
        type: localTypes.UPDATE_LOCAL,
        payload: res.data.local,
      });
    } catch (error) {}
  };

  const deleteLocal = async (id) => {
    try {
      await tokenHeader();
      await axiosClient.delete(`/local/${id}`);

      dispatch({
        type: localTypes.DELETE_LOCAL,
        payload: id,
      });
    } catch (error) {}
  };

  return (
    <localContext.Provider
      value={{
        locals: state.locals,
        createLocal,
        updateLocal,
        deleteLocal,
      }}
    >
      {children}
    </localContext.Provider>
  );
};

export default LocalState;
