

export default (state, action) => {
    switch(action.type){
        case 'LOGIN_SUCCESS':
            localStorage.setItem('token', action.payload);
            return{
                ...state,
                isAuth: true,
                loginError: null,
            }
        case 'LOGIN_ERROR':
            localStorage.removeItem('token');
                return{
                    ...state,
                    isAuth: false,
                    user: null,
                    loginError: action.payload
        }
        case 'LOGOUT_SUCCESS':
            localStorage.removeItem('token');
            return{
                ...state,
                isAuth: false,
                user: null,
                loginError: null,
        }
        default: 
            return state
    }
}