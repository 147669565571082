import Icon from 'components/icon/Icon';
import React from 'react';
import Modal from './Modal';

const ModalForm = ({showModal, handleClose, title, children}) => {
    return (  
        <Modal show={showModal}>
                <div className="small-column">
                <div className="form-header fx24 p23">
                    <h1>{title}</h1>
                        <Icon  icon='close' onClick={handleClose} isBtn/>
                </div>
                    {children}
                </div>
      </Modal>
    );
}
 
export default ModalForm;