import CloseIcon from "assets/svg/CloseIcon";
import React, { useEffect, useState } from "react";
import "./selectsearch.scss";

const MultipleSelect = ({
  options,
  onChange,
  value,
  errorMessage = "Selecciona una categoría",
}) => {
  const [focus, setFocus] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState(value);
  const containerRef = React.useRef(null);
  const inputRef = React.useRef();
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setFocus(false);
    }
  };
  const onSearch = (value) => {
    setSearchText(value);
    let results = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(results);
  };

  const onSelect = (option) => {
    let selected = [...selectedValues];
    if (selectedValues.includes(option))
      selected = selectedValues.filter((value) => value !== option);
    else selected = [...selectedValues, option];
    setSelectedValues(selected);
    onChange(selected);
  };

  const onClear = (option) => {
    setSearchText("");
    const selected = selectedValues.filter((value) => value !== option);
    setSelectedValues(selected);
    onChange(selected);
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  const showResults = searchText ? searchResults : options;
  const selected = value?.length > 0;
  const getFriendlyValues = () => {
    const friendlyValues = {};
    options.forEach((option) => {
      friendlyValues[option.value] = option.label;
    });
    return friendlyValues;
  };
  const friendlyValues = getFriendlyValues();
  return (
    <>
      <section className="select-tags">
        {selectedValues?.map((value) => (
          <section className="tag fx" key={value}>
            {friendlyValues[value]}{" "}
            <button
              className="select-search__clear"
              onClick={() => onClear(value)}
              type="button"
            >
              <CloseIcon />
            </button>
          </section>
        ))}
      </section>
      <div className="select-search input" ref={containerRef}>
        <input
          type={"text"}
          className={`formInput__input`}
          placeholder={"Buscar"}
          onFocus={() => setFocus(true)}
          onChange={(e) => onSearch(e.target.value)}
          value={searchText}
          ref={inputRef}
        />
        {!selected && <span className="error">{errorMessage}</span>}
        <ul className={`select-options ${!focus ? "select-none" : ""}`}>
          {showResults?.map((option) => (
            <section className="select-checkbox" key={option.value}>
              <input
                className="checkbox"
                type={"checkbox"}
                value={option.value}
                key={option.value}
                onClick={() => onSelect(option.value)}
                checked={selectedValues.includes(option.value)}
              />
              <label>{option.label}</label>
            </section>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MultipleSelect;
