import ProductPage from 'pages/ProductPage';
import CategoriesPage from 'pages/CategoriesPage';
import HomePage from 'pages/Home/HomePage';
import MetadataPage from 'pages/MetadataPage/MetadataPage';
import LocalPage from 'pages/LocalPage/LocalPage';


const routesApp = [
    {
        path: '/',
        label: 'Productos',
        element: <HomePage />,
        showInMenu: false,
    },
    {
        path: '/office',
        label: 'Productos',
        element: <ProductPage />,
        showInMenu: true,
    },
    {
        path: '/categories',
        label: 'Categorías',
        element: <CategoriesPage />,
        showInMenu: true,
    },
    {
        path: '/local',
        label: 'Locales',
        element: <LocalPage />,
        showInMenu: true,
    },
    {
        path: '/information',
        label: 'Información adicional',
        element: <MetadataPage />,
        showInMenu: true,
    }
]

export default routesApp;