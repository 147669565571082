import "./form.scss";

const FormLayout = ({ children, buttonText, onSubmit }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="form p03">
      <form onSubmit={handleSubmit}>
        {children}
        <button type="submit" className="button--form">{buttonText || "Submit"}</button>
      </form>
    </div>
  );
};

export default FormLayout;