import TextArea from "components/textarea/TextArea";
import React from "react";
import Input from "./Input";
import InputLayout from "./InputLayout";
import PropTypes from "prop-types";
import InputFile from "components/form/InputFile";
import SelectSearch from "components/form/SelectSearch";
import MultipleSelect from "components/form/MultipleSelect";

const FormInput = (props) => {
  const { type, label, errorMessage } = props;
  const renderInput = (type) => {
    switch (type) {
      case "textarea":
        return TextArea;
      case "file":
        return InputFile;
      case "select-search":
        return SelectSearch;
      case "multiple-select":
        return MultipleSelect;
      default:
        return Input;
    }
  };
  const InputComponent = renderInput(type);

  return (
    <InputLayout label={label} errorMessage={errorMessage}>
      <InputComponent {...props} />
    </InputLayout>
  );
};

export default FormInput;

FormInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
};
