import avatar from './avatar.svg';
import down from './down.svg';
import edit from './edit.svg';
import trash from './trash.svg';
import upload from './upload.svg'
import show from './show.svg'
import hide from './hide.svg'
import search from './search.svg'
import menu from './menu.svg'
import close from './close.svg'

const icons = {
    avatar,
    down,
    edit,
    trash,
    upload,
    show,
    hide,
    search,
    menu,
    close
}

export default icons