import React from "react";
import "./inputLayout.scss";

const InputLayout = ({ children, label, errorMessage }) => {
  return (
    <section className="input-container">
      <label className="input-label">{label}</label>
      {children}
      <span className="error">{errorMessage}</span>
    </section>
  );
};

export default InputLayout;
